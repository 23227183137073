@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap);
/* font-family: 'Roboto', sans-serif; */

/* font-family: 'Roboto Slab', serif; */

/* font-family: 'Lato', sans-serif; */

/* bootstrap */

html {
  box-sizing: border-box;
}

@media screen and (min-width: 1300px) {
  /*  */
}


*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
}


.Logo_logoBox__1I7Op {
    width: 160px;
    height: 40px;
}

.Logo_logo__3uuF2 {
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
}
.UserLogo_logoBox__2IDIb {
    border-radius: 50%;
    overflow: hidden;
}

.UserLogo_logo__2FFP- {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
}
.modal-auto_width {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.modal-auto_hide{
    display: none;
}
:root {
    --color-dark-blue: #07223d;
    --color-light-blue: #0a3158;
    --color-accent: #F1B750;
    --color-brown: #805928;
    --color-grey: #717171;
    --color-white: #FFFFFF;
    --color-red: #E93D3D;
    --color-dark: #252020;
    --color-text: #07223d;
    --color-light-grey: #bebebe;
    --color-text: #07223d;
}
.MiniLoading_line__NUbjE {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--color-light-blue);
}

.MiniLoading_line__NUbjE {
    margin-right: 1px;
}

.MiniLoading_load__U2ECH .MiniLoading_line__NUbjE:nth-last-child(1) {
    -webkit-animation: MiniLoading_loadingC__2VbL2 0.6s 0.1s linear infinite;
            animation: MiniLoading_loadingC__2VbL2 0.6s 0.1s linear infinite;
}

.MiniLoading_load__U2ECH .MiniLoading_line__NUbjE:nth-last-child(2) {
    -webkit-animation: MiniLoading_loadingC__2VbL2 0.6s 0.2s linear infinite;
            animation: MiniLoading_loadingC__2VbL2 0.6s 0.2s linear infinite;
}

.MiniLoading_load__U2ECH .MiniLoading_line__NUbjE:nth-last-child(3) {
    -webkit-animation: MiniLoading_loadingC__2VbL2 0.6s 0.3s linear infinite;
            animation: MiniLoading_loadingC__2VbL2 0.6s 0.3s linear infinite;
}

.MiniLoading_load__U2ECH .MiniLoading_line__NUbjE:nth-last-child(4) {
    -webkit-animation: MiniLoading_loadingC__2VbL2 0.6s 0.4s linear infinite;
            animation: MiniLoading_loadingC__2VbL2 0.6s 0.4s linear infinite;
}

.MiniLoading_load__U2ECH .MiniLoading_line__NUbjE:nth-last-child(5) {
    -webkit-animation: MiniLoading_loadingC__2VbL2 0.6s 0.5s linear infinite;
            animation: MiniLoading_loadingC__2VbL2 0.6s 0.5s linear infinite;
}

.MiniLoading_load__U2ECH .MiniLoading_line__NUbjE:nth-last-child(6) {
    -webkit-animation: MiniLoading_loadingC__2VbL2 0.6s 0.6s linear infinite;
            animation: MiniLoading_loadingC__2VbL2 0.6s 0.6s linear infinite;
}

.MiniLoading_load__U2ECH .MiniLoading_line__NUbjE:nth-last-child(7) {
    -webkit-animation: MiniLoading_loadingC__2VbL2 0.6s 0.7s linear infinite;
            animation: MiniLoading_loadingC__2VbL2 0.6s 0.7s linear infinite;
}

@-webkit-keyframes MiniLoading_loadingC__2VbL2 {
    0 {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }

    50% {
        -webkit-transform: translate(0, 5px);
                transform: translate(0, 5px);
    }

    100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
}

@keyframes MiniLoading_loadingC__2VbL2 {
    0 {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }

    50% {
        -webkit-transform: translate(0, 5px);
                transform: translate(0, 5px);
    }

    100% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
}
.UserModalBlock_container__OXXwj {
    position: relative;
    width: 300px;
    padding: 24px 0;
}

.UserModalBlock_logoutHeader__3o0Ir {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 70px;
    width: 100%;
    border-bottom: 1px solid #07223d;
}

.UserModalBlock_header__2zV_m {
    position: relative;
    z-index: 2;
    display: flex;
    padding: 0 24px 30px;
}

.UserModalBlock_userInfo__3BVld {
    padding: 14px 0 0 16px;
    width: 70%;
}

.UserModalBlock_userName__jt9Mo {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 8px;
    color: var(--color-dark);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.UserModalBlock_userAccountTitle__2I-us {
    color: var(--color-dark);
    margin-right: 5px;
}

.UserModalBlock_greenLicenses__2kQ4x {
    margin-right: 5px;
    color: #27AE60;
}

.UserModalBlock_redLicenses__SjEPR {
    margin-right: 5px;
    color: #E93D3D;
}

.UserModalBlock_userNameLicenses__3R43C {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.17;
    color: rgb(113, 113, 113);
}


.UserModalBlock_button__1BQR1 {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 8px 8px 8px 24px;
    border-top: 1px solid rgba(179, 201, 230, 0.4);
    border-bottom: 1px solid rgba(179, 201, 230, 0.4);
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.17;
    cursor: pointer;
    transition: all 250ms cubic-bezier(0.215, 0.610, 0.355, 1);
}

.UserModalBlock_button__1BQR1:not(:last-of-type) {
    margin-bottom: 8px;
}

.UserModalBlock_button__1BQR1:hover {
    background-color: #E5E5E5;;
}

.UserModalBlock_buttonText__1yzAp {
    white-space: nowrap;
    /* display: flex;
    flex-wrap: nowrap; */
    overflow-x: hidden;
    margin-left: 9px;
}

.UserModalBlock_unsubscribe__wsPNl {
    color: black;
}

.UserModalBlock_upgradeButton__242p0 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-top: 35px;
    margin: 0 auto;
}

.UserModalBlock_logOutButton__VkTkK {
    position: absolute;
    top: -10px;
    right: 10px;
    cursor: pointer;
    transition: all 150ms linear;
}

.UserModalBlock_logOutButton__VkTkK:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05)
}


.UserModalBlock_settingsButton__HE0h3  {
    display: flex;
    justify-content: center;
    padding-top: 16px;
}

.UserModalBlock_popoverText__2zy7u { 
    position: relative;  
    padding: 8px 28px 8px 47px; 
    font-weight: 500;
    font-size: 14px;
    line-height: 1.17;
    color: rgb(33, 150, 83);
}

.UserModalBlock_popoverText__2zy7u::before, 
.UserModalBlock_popoverText__2zy7u::after {
    content: '';
    position: absolute;
    display: block;
    background-color: #27AE60;
    border-radius: 1px;
}

.UserModalBlock_popoverText__2zy7u::before {
    left: 20px;
    top: 17px;
    height: 2px;
    width: 8px;
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg)
}

.UserModalBlock_popoverText__2zy7u::after {
    left: 24px;
    top: 15px;
    height: 2px;
    width: 16px;
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg)
}
.AddModalBox_iconBox__1H4a6 {
    display: flex;
}

.AddModalBox_titleBox__3KdZV {
    flex-grow: 2;
    display: flex; 
    align-items: center;
}

.AddModalBox_titleBox__3KdZV {
    flex-grow: 2; 
}

.Switch_container__3BXav {
    display: flex;
    align-items: center;

}

.Switch_switchBox__1TyQN {
    position: relative;
    margin: 0 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.Switch_time__3rP6p, .Switch_timeActive__2un4c  {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.17;
    text-decoration: none;
    color: var(--color-dark-blue);
}

.Switch_timeActive__2un4c {
    position: relative;
    font-weight: 500;
}

.Switch_timeActive__2un4c::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -6px;
    display: block;
    height: 2px;
    width: 65%;
    background-color: var(--color-dark-blue);
}

.PlanColumn_container__1lApc, .PlanColumn_chousenContainer__2UYwi {
    text-align: center;
    line-height: 1.17;
    border: 1px solid #B3C9E6;
    border-radius: 4px;
    cursor: pointer;
}

.PlanColumn_chousenContainer__2UYwi {
    border: 2px solid var(--color-light-blue); 
}

.PlanColumn_header__wdGWG {
    padding-bottom: 9px;
}

.PlanColumn_name__BIeGW {
    padding: 24px 10px 6px; 
    font-weight: 500;
    font-size: 16px;
    color: var(--color-dark-blue)
}

.PlanColumn_price__--D24 {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    font-weight: 500;
    font-size: 24px;
    color: var(--color-light-blue);
}

.PlanColumn_pricePeriod__2dPWI {
    position: absolute;
    left: 100%;
    bottom: 3px;
    font-weight: 400;
    font-size: 10px;
    color: var(--color-dark-blue);
}

.PlanColumn_planBlock__1Dcds {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    height: 40px;
    font-weight: 700;
    font-size: 18px;
    color: rgb(179, 201, 230);
}

.PlanColumn_chousenHeader__7Vra8 {
    background-color: var(--color-light-blue);
    padding-bottom: 8px;
}

.PlanColumn_chousenHeader__7Vra8 *,
.PlanColumn_chousenHeader__7Vra8 *::before,
.PlanColumn_chousenHeader__7Vra8 *::after {
  color: var(--color-white);
}

.PlanColumn_chousenPlanDescription__17HzC *,
.PlanColumn_chousenPlanDescription__17HzC *::before,
.PlanColumn_chousenPlanDescription__17HzC *::after {
  color: var(--color-dark-blue);
}


.StripeCardField_error__1TK3e {
  /* color: inherit; */
  color: #E93D3D;
  display: flex;
  justify-content: center;
  padding: 15px 15px 0;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  /* transform: translateY(-15px); */
  -webkit-animation: StripeCardField_fade__T-Uvp 150ms ease-out;
          animation: StripeCardField_fade__T-Uvp 150ms ease-out;
  -webkit-animation-delay: 50ms;
          animation-delay: 50ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.StripeCardField_error__1TK3e svg {
  margin-right: 10px;
}
.Checkbox_container__2HTFP {
    display: flex;
    align-items: center;
}

.Checkbox_checkbox__1_PzG {
    position: relative;
    width: 18px;
    height: 18px;
}

.Checkbox_input__1ACuC {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
        cursor: pointer;
}

.Checkbox_check__91sVH {
    width: 100%;
    height: 100%;
    border: 2px solid var(--color-dark-blue);
    background-color: none;
    border-radius: 2px;
    pointer-events: none;
    transition: all 250ms cubic-bezier(0.215, 0.610, 0.355, 1);
}

.Checkbox_check__91sVH::before, .Checkbox_check__91sVH::after {
    content: "";
    position: absolute;
    display: block;
    border-radius: 1px;
    background-color: none;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    transition: all 250ms cubic-bezier(0.215, 0.610, 0.355, 1);
    cursor: pointer;
}

.Checkbox_check__91sVH::before {
    top: 7px;
    left: 5px;
    width: 2px;
    height: 6px;
}

.Checkbox_check__91sVH::after {
    top: 8px;
    left: 6px;
    width: 9px;
    height: 2px;
}

.Checkbox_label__3CJwf, .Checkbox_labelChecked__3gYpl {
    display: none;
}

.Checkbox_label__3CJwf:not(:empty), .Checkbox_labelChecked__3gYpl:not(:empty) {
    display: inline-block;
    margin-left: 10px;   
    margin-bottom: 0; 
    font-weight: 400;
    font-size: 14px;
    line-height: 1.17;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}

.Checkbox_label__3CJwf {
    color: var(--color-light-grey);
}

.Checkbox_labelChecked__3gYpl {
    color: var(--color-dark-blue);
}

.Checkbox_input__1ACuC:checked + .Checkbox_check__91sVH {
    background-color: var(--color-dark-blue);
}

.Checkbox_input__1ACuC:checked + .Checkbox_check__91sVH::after {
    background-color: var(--color-white);
}

.Checkbox_input__1ACuC:checked + .Checkbox_check__91sVH::before {
    background-color: var(--color-white);
}
.BuyPlanBlock_planTitle__2WyVM {
    padding-top: 8px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.17;
    color: var(--color-dark-blue);
    display: flex;
    justify-content: space-between;
}

.BuyPlanBlock_goBackToAllPlans__3901m {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-accent);
    cursor: pointer;
    transition: all 150ms cubic-bezier(0.215, 0.610, 0.355, 1);
}

.BuyPlanBlock_goBackToAllPlans__3901m:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.BuyPlanBlock_label__sGmQH {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.17;
    color: var(--color-dark-blue);
    margin-top: 24px;
    margin-bottom: 12px;
}

.BuyPlanBlock_inputsGrid__GhMHA {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 24px;
    margin-bottom: 24px;
    padding-top: 20px;
}

.BuyPlanBlock_buttonBox__27YUf {
    padding-top: 20px;
}

.BuyPlanBlock_loading__2IPzR {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.BuyPlanBlock_infoBox__1sIAY {
    padding: 50px 10px;
    text-align: center;
    color: var(--color-dark-blue);
}

.BuyPlanBlock_invoiceLink__31wvp {
    margin-bottom: 15px;
}

.BuyPlanBlock_infoTitle__3BiWL {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}

.BuyPlanBlock_cheboxContainerPayment__2a2en{
    margin-top: 10px;
}
.UpgradePlan_container__1mj-l {
    color: var(--color-dark-blue);
}

.UpgradePlan_header__1s7hh {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 20px;
}

.UpgradePlan_subHeader__1f4FU {
    display: flex;
    align-items: flex-start;
}

.UpgradePlan_titleCurrentPlan__1AmR3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 8px;
    margin-right: 70px;
}

.UpgradePlan_currentPlan__J-_Md {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.17;
}

.UpgradePlan_buttonsBox__37uAs {
    display: flex;
}

.UpgradePlan_buttonsBox__37uAs > *:not(:first-child) {
    margin-left: 15px;
}


/* .chousenPlan {
    width: 125px;
    text-align: right;
    margin-right: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.17;
    color: rgb(113, 113, 113);
} */

.UpgradePlan_plansBox__feS2a {
    display: flex;
}

.UpgradePlan_plansDescription__R36Is, .UpgradePlan_plansColumn__22vrL {
    flex-basis: 25%;
}

.UpgradePlan_plansColumn__22vrL {
    margin-left: 20px;
}

.UpgradePlan_plansDescription__R36Is {
    padding-top: 88px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.17;
}

.UpgradePlan_plansDescriptionItem__1wf1x {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 40px;
    color: var(--color-dark-blue);
}

.UpgradePlan_plansDescriptionItem__1wf1x::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: calc(400% + 60px);
    height: 1px;
    background-color: rgba(179, 201, 230, 0.2);;
}

.UpgradeLicensePlan_container__3OD0c {
    color: var(--color-dark-blue);
}

.UpgradeLicensePlan_header__3KR3W {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 20px;
}

.UpgradeLicensePlan_subHeader__3PSXO {
    display: flex;
    align-items: flex-start;
}

.UpgradeLicensePlan_titleCurrentPlan__2gkDk {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 8px;
    margin-right: 70px;
}

.UpgradeLicensePlan_currentPlan__8kSkL {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.17;
}

.UpgradeLicensePlan_buttonsBox__1oIu8 {
    display: flex;
}

.UpgradeLicensePlan_buttonsBox__1oIu8 > *:not(:first-child) {
    margin-left: 15px;
}


/* .chousenPlan {
    width: 125px;
    text-align: right;
    margin-right: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.17;
    color: rgb(113, 113, 113);
} */

.UpgradeLicensePlan_plansBox__3e659 {
    display: flex;
}

.UpgradeLicensePlan_plansDescription__ZI1Gg, .UpgradeLicensePlan_plansColumn__uj64C {
    flex-basis: 25%;
}

.UpgradeLicensePlan_plansColumn__uj64C {
    margin-left: 20px;
}

.UpgradeLicensePlan_plansDescription__ZI1Gg {
    padding-top: 88px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.17;
}

.UpgradeLicensePlan_plansDescriptionItem__12qNy {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 40px;
    color: var(--color-dark-blue);
}

.UpgradeLicensePlan_plansDescriptionItem__12qNy::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: calc(400% + 60px);
    height: 1px;
    background-color: rgba(179, 201, 230, 0.2);;
}

.AppBar_header__mjMCE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 1300px;
  padding: 15px 40px;
  background-color: var(--color-white);
  box-shadow: 0px 2px 5px rgba(100, 100, 100, 0.102);
}

.AppBar_mainNav__3LZ12 {
  display: flex;
  align-items: center;
}

.AppBar_mainNav__3LZ12 > *:first-child {
  margin-right: 40px;
}

.AppBar_navLink__xouiq {
  position: relative;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.17;
  text-decoration: none;
  color: var(--color-light-blue);
  margin-left: 10px;
  cursor: pointer;
}

.AppBar_navLink__xouiq:not(:last-of-type) {
  margin-right: 40px;
}

.AppBar_navLink__xouiq:hover {
  text-decoration: none;
  color: #07223d; 
}

.AppBar_activeLink__2Gj9m {
  font-weight: 500;
}

.AppBar_activeLink__2Gj9m::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -6px;
  display: block;
  height: 2px;
  width: 65%;
  background-color: var(--color-light-blue);
}

.AppBar_userNav__213JW {
  display: flex;
  align-items: center;
}

.AppBar_userName__Qx7o6 {
  margin-left: 30px;
  margin-right: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.17;
  color: var(--color-light-blue);
  text-decoration: none;
  cursor: pointer;
}

.AppBar_notDecorateNavLink__1gXvb {
  text-decoration: none;
}

.AppBar_userName__Qx7o6:hover {
  text-decoration: none;
}

.AppBar_linkIsCoppied__2JP37 {
  position: relative;
  padding: 7px 7px 7px 31px;
  background-color: #27AE60;
  color: var(--color-white);
  border-radius: 4px;
  cursor: pointer;
}
/*  */

.AppBar_linkIsCoppied__2JP37::before,
.AppBar_linkIsCoppied__2JP37::after {
  content: '';
  position: absolute;
  display: block;
  background-color: var(--color-white);
  border-radius: 1px;
}

.AppBar_linkIsCoppied__2JP37::before {
  left: 7px;
  top: 21px;
  height: 2px;
  width: 8px;
  -webkit-transform: rotate(50deg);
          transform: rotate(50deg)
}

.AppBar_linkIsCoppied__2JP37::after {
  left: 10px;
  top: 18px;
  height: 2px;
  width: 16px;
  -webkit-transform: rotate(-50deg);
          transform: rotate(-50deg)
}
.Loading_cloudShadowContainer__2SiFr {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.Loading_fluffyCloudBox__3EDdb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    -webkit-transform: rotateY(180deg); 
            transform: rotateY(180deg);
}

.Loading_fluffyCloud__2Ly2T {
    fill: var(--color-light-blue);
    -webkit-animation: Loading_float__2Ksog 2.3s infinite linear;
            animation: Loading_float__2Ksog 2.3s infinite linear;
}

.Loading_shadow__2HrWT {
    width: 60px;
    height: 16px;
    border-radius: 100%;
    margin-top: 8px;
    background-color: var(--color-light-blue);
    opacity: 0.5;
    -webkit-animation: Loading_change-size__2h6a9 2.3s infinite linear;
            animation: Loading_change-size__2h6a9 2.3s infinite linear;
}

@-webkit-keyframes Loading_float__2Ksog {
    0% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

    50% {
        -webkit-transform: translateY(-7px);
                transform: translateY(-7px);
    }

    100% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@keyframes Loading_float__2Ksog {
    0% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }

    50% {
        -webkit-transform: translateY(-7px);
                transform: translateY(-7px);
    }

    100% {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@-webkit-keyframes Loading_change-size__2h6a9 {
    50% {
        -webkit-transform: scaleX(.8);
                transform: scaleX(.8);
    }
}

@keyframes Loading_change-size__2h6a9 {
    50% {
        -webkit-transform: scaleX(.8);
                transform: scaleX(.8);
    }
}
