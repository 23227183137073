/* font-family: 'Roboto', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

/* font-family: 'Roboto Slab', serif; */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* font-family: 'Lato', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap');

/* bootstrap */
@import 'bootstrap/dist/css/bootstrap.min.css';

html {
  box-sizing: border-box;
}

@media screen and (min-width: 1300px) {
  /*  */
}


*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
}

