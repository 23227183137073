.logoBox {
    width: 160px;
    height: 40px;
}

.logo {
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
}