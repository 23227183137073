:root {
    --color-dark-blue: #07223d;
    --color-light-blue: #0a3158;
    --color-accent: #F1B750;
    --color-brown: #805928;
    --color-grey: #717171;
    --color-white: #FFFFFF;
    --color-red: #E93D3D;
    --color-dark: #252020;
    --color-text: #07223d;
    --color-light-grey: #bebebe;
    --color-text: #07223d;
}